import styled from 'styled-components'

import { Accordion as Acordion } from '@material-ui/core'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'
import { Button as But } from 'components'

import fonts from '../../styles/fonts.json'
import color from '../../styles/color.json'

export const Container = styled.div`
  padding-top: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    padding-top: ${({ login }) => (login ? '112px' : '72px')};
  }
`

export const Title = styled.h1`
  ${fonts.h1};
  color: ${color.primary};

  p {
    ${fonts.h1}
    color: ${color.gray[900]};
  }

  @media (max-width: 600px) {
    ${fonts.h1_mobile};

    p {
      ${fonts.h1_mobile}
    }
  }
`
export const H2 = styled.h2`
  ${fonts.h2};
  color: ${color.gray[600]};

  @media (max-width: 600px) {
    ${fonts.p1};
  }
`
export const H4 = styled.h4`
  ${fonts.h4};
  color: ${color.gray[800]};
`

export const H5 = styled.h5`
  ${fonts.h5};
  color: ${color.gray[800]};
`

export const H6 = styled.h6`
  ${fonts.h6};
  color: ${color.gray[900]};

  @media (max-width: 600px) {
    ${fonts.s3};
  }
`
export const P1 = styled.p`
  ${fonts.p1};
  color: ${color.white};
`
export const P2 = styled.p`
  ${fonts.p2};
  color: ${color.white};
`

export const DivButton = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 80px !important;
  padding-right: 108px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 39px !important;
    padding-right: 0px;
  }
`

export const Button = styled(But)`
  width: 100%;
  height: 52px;
  max-width: 232px;

  span {
    ${fonts.p1}
  }

  &.MuiButton-containedSecondary {
    color: #ffffff;
    background-color: ${color.secondary};
  }
`

export const ButtonView = styled(But)`
  width: 100%;
  height: 52px;
  max-width: 177px;
  margin-top: 0px !important;

  span {
    ${fonts.p1}
  }
`

export const ButtonWhatsGroup = styled(But)`
  width: 100%;
  height: 52px;
  max-width: 237px;

  img {
    width: 25px;
    margin-right: 13px;
  }

  span {
    ${fonts.p1}
  }

  &.MuiButton-outlinedPrimary {
    color: #42ace0;
    border: 1px solid #42ace0;
  }

  :hover {
    &.MuiButton-outlinedPrimary {
      color: #ffffff;
      background-color: #42ace0;
      border: 0px;
    }
  }
`

export const ButtonWhatsSup = styled(But)`
  width: 100%;
  height: 52px;
  max-width: 274px;

  span {
    ${fonts.p1}
  }

  &.MuiButton-containedSecondary {
    color: #ffffff;
    background-color: ${color.green};
  }

  :hover {
    img {
      filter: invert(51%) sepia(88%) saturate(1596%) hue-rotate(96deg) brightness(90%) contrast(102%);
    }

    &.MuiButton-containedSecondary {
      color: ${color.green};
      background-color: transparent;
      border: 1px solid ${color.green};
    }
  }
`

export const SectionImage = styled.section`
  display: flex;
  justify-content: center;

  background-size: cover;
  background-position: center;

  width: 100%;
  /*  max-width: 1200px; */
  height: 662px;

  div {
    width: 100%;
    max-width: 1800px;
    margin-top: 140px;
    margin-left: 108px;

    div {
      width: 100%;
      margin-top: 0px;
      margin-left: 0px;

      h6 {
        margin-top: 26px;
      }
    }
  }

  @media (max-width: 600px) {
    height: 520px;

    div {
      margin-top: 92px;
      margin-left: 14%;
    }

    h6 {
      margin-top: 0px !important;
    }
  }
`
export const SectionCompany = styled.section`
  overflow: visible;
  width: 100%;
  max-width: 1800px;

  background-image: url('img/element-home3.png');
  background-position: 0% 0%;
  background-repeat: no-repeat;

  h1 {
    margin-top: 52px;
  }
  h2 {
    margin-top: 16px;
  }

  @media (max-width: 600px) {
    background-size: 40%;
    h1 {
      margin-top: 84px;
    }
    h2 {
      margin-top: 18px;
    }
  }
`

export const Sequence = styled.div`
  display: flex;
  margin-top: 77px;
  flex-wrap: wrap;
  justify-content: center;

  div {
    display: flex;
    flex-direction: row;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 236px;

    text-align: center;

    h6 {
      margin-top: 20px;
    }
  }

  @media (max-width: 1260px) {
    gap: 30px 0px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    gap: 20px;

    div {
      flex-direction: column;
      gap: 20px;
    }

    .item {
      gap: 0px;
    }
  }

  @media (max-width: 600px) {
    h6 {
      ${fonts.p2};
    }
  }
`
export const Arrow = styled.img`
  align-self: baseline;
  position: relative;
  top: 14px;

  @media (max-width: 750px) {
    transform: rotate(90deg);
    align-self: center;
    top: 0px;
  }
`

export const Loan = styled.section`
  width: 100%;
  display: flex;
  gap: 40px;
  padding-top: 104px;
  margin-bottom: 52px;
  flex-wrap: wrap;
  justify-content: center;

  background-image: url('img/element-home8.png'), url('img/element-home3.png');
  background-position: 0% 0%, 100% 0%;
  background-repeat: no-repeat, no-repeat;
  overflow: visible;

  h1 {
    margin-top: 0px;
    margin-bottom: 48px;
    color: ${color.gray[900]};
  }

  ul {
    list-style: none;
    margin-bottom: 48px;
    list-style-position: outside;

    li {
      ${fonts.h2};
      color: ${color.gray[600]};
      margin-bottom: 24px;
      display: flex;

      ::before {
        content: '•';
        padding-right: 8px;
        color: ${color.secondary};
        font-size: 24px;
        margin-right: 38px;
      }
    }
  }

  @media (max-width: 1130px) {
    background-position: 0% 0%, 100% 65%;

    padding-top: 84px;
    margin-bottom: 84px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
    }
  }

  @media (max-width: 600px) {
    background-position: 0% 0%, 100% 65%;
    background-size: 15%, 50%;

    h1 {
      ${fonts.h2_mobile}
      margin-bottom: 50px;
    }

    ul {
      li {
        ${fonts.p1}
        display: inline-flex;
        padding: 0px 10px;

        ::before {
          margin-right: 11px;
        }
      }
    }

    @media (max-width: 440px) {
      background-position: 0% 0%, 100% 80%;
    }

    img {
      width: 100%;
      min-width: 180px;
      max-width: 387px;
      /* padding: 0 70px 0 70px !important; */
    }
  }
`

export const Investment = styled.section`
  width: 100%;
  display: flex;
  gap: 40px;
  padding-top: 52px;
  padding-bottom: 52px;
  flex-wrap: wrap-reverse;
  justify-content: center;

  background: ${color.blue_background};

  background-image: url('img/element-home4.png');
  background-position: 0% 0%;
  background-repeat: no-repeat;
  overflow: visible;

  h1 {
    width: 100%;
    max-width: 700px;
    margin-top: 0px;
    margin-bottom: 48px;
    color: ${color.gray[900]};
  }

  ul {
    list-style: none;
    margin-bottom: 48px;

    li {
      ${fonts.h2};
      color: ${color.gray[600]};
      margin-bottom: 24px;
      display: flex;

      ::before {
        content: '•';
        padding-right: 8px;
        color: ${color.secondary};
        font-size: 24px;
        margin-right: 38px;
      }
    }
  }

  @media (max-width: 1150px) {
    background-position: 0% 93%;
  }

  @media (max-width: 1130px) {
    margin-top: 84px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
    }
  }

  @media (max-width: 600px) {
    background-size: 28%;

    padding-top: 34px;
    margin-top: 0px;
    h1 {
      ${fonts.h2_mobile}
      margin-bottom: 50px;
    }

    ul {
      li {
        ${fonts.p1}
        display: inline-flex;
        padding: 0px 10px;
        ::before {
          margin-right: 11px;
        }
      }
    }

    img {
      width: 100%;
      min-width: 180px;
      max-width: 387px;
      /*    padding: 0 70px 0 70px !important; */
    }
  }
`

export const WhoAre = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px 128px;

  text-align: center;
  background: ${color.blue[500]};

  h1 {
    color: ${color.white};
    margin-bottom: 48px;
  }

  h2 {
    color: ${color.white};
    width: 100%;
    max-width: 971px;
  }

  @media (max-width: 600px) {
    padding: 48px 19px;
    h1 {
      ${fonts.h2_mobile}
      line-height: 35.5px;
    }
    h2 {
      ${fonts.h5}
      line-height: 47px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
    }
  }
`
export const WhoAreElement = styled.div`
  position: absolute;

  ${({ pos }) => (pos === 'left' ? 'left: 8%' : 'right:8%')};

  @media (max-width: 1050px) {
    left: 0px;
    right: 0px;
    position: relative;
    transform: rotate(90deg);
    width: 80px;
  }
`
export const Media = styled.section`
  width: 100%;
  max-width: 1800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px 89px 0px 89px;

  text-align: center;

  background-image: url('img/element-home6.png'), url('img/element-home12.png');
  background-position: 0% 0%, 100% 100%;
  background-repeat: no-repeat, no-repeat;
  overflow: visible;

  h1 {
    color: ${color.gray[900]};
  }

  .icons-media {
    width: inherit;
    height: inherit;
    border: 30px solid white;
    text-align: center;

    box-sizing: border-box;
  }

  @media (max-width: 600px) {
    padding: 52px 17px 0px 17px !important;
    background-size: 15%, 20%;

    h1 {
      ${fonts.h2_mobile};
      margin-bottom: 18px;
    }

    h2 {
      ${fonts.p1};
      text-align: center;
      width: 226px;
      margin-bottom: 10px;
    }

    .icons-media {
      border: 11px solid white;
    }
  }
`

export const Depositions = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${color.blue_background};
`
export const ContainerDepositions = styled.div`
  width: 100%;
  max-width: 1800px;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 52px 100px;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }

  h1 {
    margin-top: 16px;
  }

  h2 {
    width: 100%;
    max-width: 324px;
  }

  button {
    :disabled {
      filter: grayscale(100%) brightness(139%);
    }
  }

  @media (max-width: 600px) {
    padding: 40px 0px;

    h1 {
      padding: 0px 22px;
    }
    h2 {
      ${fonts.h5};
      max-width: 600px;
      padding: 0px 22px;
    }
    h5 {
      line-height: 1;
    }
  }
`
export const ImgButtonDeposition = styled.img`
  @media (max-width: 390px) {
    width: 30px;
  }
`

export const CardDeposition = styled.div`
  width: 100%auto;
  max-width: 322px;
  height: 356px;
  padding: 32px !important;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  box-shadow: 0px -1px 36px -4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  box-sizing: border-box;

  h5 {
    width: max-content;
  }

  div {
    gap: 40px;
    div {
      gap: 0px;
    }
  }

  p {
    ${fonts.s1};
    color: ${color.gray[500]};

    span {
      color: ${color.blue[500]};
    }
  }

  img {
    width: 68px;
    border-radius: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
    max-width: 255px;
    height: 100%;

    div {
      gap: 13px;
      div {
        gap: 0px;
      }
    }
  }

  @media (max-width: 390px) {
    padding: 20px;
    div {
      gap: 2px;
    }
  }
`

export const WhatsGroup = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url('img/element-home9.svg'), url('img/element-home11.png');
  background-position: 100% 50%, 100% 100%;
  background-repeat: no-repeat, no-repeat;
  overflow: visible;

  @media (max-width: 1256px) {
    background-image: url('img/element-home9.svg'), url('img/element-home10.svg');
    background-position: 100% 89%, 100% 100%;
  }

  @media (max-width: 600px) {
    background-size: 50%, 75%;
  }
`
export const ContainerWhatsGroup = styled.div`
  width: 100%;
  max-width: 1800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 27%;
  padding: 52px 145px;

  h1 {
    margin-top: 34px;
    margin-bottom: 40px;
  }

  h2 {
    width: 100%;
    max-width: 644px;
    margin-bottom: 48px;
  }

  .image {
  }

  @media (max-width: 1470px) {
    gap: 10%;
  }

  @media (max-width: 1242px) {
    gap: 93px;

    .image {
      width: 40%;
    }
  }

  @media (max-width: 600px) {
    padding: 40px 0px;
    gap: 48px;

    h1 {
      padding: 0px 22px;
    }
    h2 {
      ${fonts.h5};
      max-width: 600px;
      padding: 0px 22px;
    }
    h5 {
      line-height: 1;
    }

    .whatsButton {
      display: flex;
      justify-content: center;
    }
  }
`

export const HR = styled.hr`
  width: 87%;
  height: 2px;
  background: #eeeeee;
`

export const CommonQuestions = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url('img/element-home13.png'), url('img/element-home7.png');
  background-position: 0% 15%, 100% 100%;
  background-repeat: no-repeat, no-repeat;
  overflow: visible;

  padding: 52px 20px;
  text-align: center;

  h1 {
    color: ${color.gray[900]};
    margin-bottom: 4px;
  }

  h2 {
    margin-bottom: 48px;
  }

  h4 {
    color: ${color.gray[600]};
    margin-top: 70px;
  }

  h5 {
    color: ${color.gray[600]};
  }

  button {
    margin-top: 38px;
    img {
      margin-right: 13px;
    }
  }

  @media (max-width: 600px) {
    background-position: 0% 2%, 100% 100%;
    background-size: 15%, 0%;

    h1 {
      ${fonts.h2_mobile}
    }

    h2 {
      ${fonts.h5}
    }

    h4 {
      ${fonts.s1}
    }
  }
`
export const Accordion = styled(Acordion)`
  h4 {
    color: ${color.gray[900]};
    margin: 11.5px 0px;
  }

  &&.MuiAccordion-root {
    ${palette};
    ${spacing};
    ${typography};
    ${borders};
    ${flexbox};
    ${shadows};
    ${sizing};

    width: 100%;
    max-width: 1064px;
    padding-left: 17px;
    margin-bottom: 32px;

    border: 1px solid rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    box-shadow: 0px -1px 36px -4px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    text-align: start;

    ::before {
      height: 0px;
    }

    &&.Mui-expanded {
      padding: 0px 16px 18px 16px;
    }
  }

  @media (max-width: 600px) {
    &&.MuiAccordion-root {
      margin-bottom: 32px;
      padding-left: 16px;
      text-align: start;

      h4 {
        ${fonts.p1}
        margin: 8px 0px;
      }
      h5 {
        ${fonts.p2};
      }
    }
  }
`

export const QuestionsElement = styled.div`
  position: absolute;
  z-index: -10;

  ${({ pos }) => (pos && pos === 'left' ? 'left: 2%; margin-bottom: 480px; ' : 'right:2%; margin-top: 575px;')};

  ${({ circle }) => circle && 'left: 0% !important; right: auto; margin-bottom: 0px; margin-top: 0px;'};

  @media (max-width: 1050px) {
    display: none;
    ${({ circle }) => circle && 'display: block;'};
  }
  @media (max-width: 585px) {
    ${({ circle }) => circle && 'top: 6300px; width: 70px;'};
  }
  @media (max-width: 370px) {
    ${({ circle }) => circle && 'top: 6500px; width: 70px;'};
  }
`

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 52px 101px;

  background: ${color.primary};

  div {
    max-width: 1800px;
  }

  a {
    ${fonts.p1}
    color: white;
    text-decoration: none;
  }

  hr {
    height: 2px;
    background: rgba(196, 196, 196, 0.19);
    margin: 24px 0px;
  }

  @media (max-width: 600px) {
    padding: 50px 20px;
  }
`

export const FooterLinks = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 65px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    gap: 16px;
    flex-direction: column;
  }
`

export const FooterInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 65px;

  @media (max-width: 900px) {
    flex-wrap: wrap;
    gap: 24px;
  }
`

export const FooterBanks = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row !important;
  padding: 70px 0px;
  width: 100%;
  max-width: 1800px;

  .div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
  }

  span {
    display: inline;
    width: 1px;
    border: 1px inset;
    margin: 5px;
    height: 48px;
    border-color: rgba(196, 196, 196, 0.19);
  }

  @media (max-width: 900px) {
    flex-direction: column !important;
    gap: 20px;
    padding: 48px 0px;

    h4 {
      ${fonts.h5};
      width: 100%;
    }

    .div {
      width: 273px;
      flex-direction: column;
      gap: 40px;
    }

    span {
      display: none;
    }
  }
`
export const ImageBorder = styled.div`
  div {
    border-radius: 50% !important;
    div {
      border-radius: 50% !important;
      img {
        border-radius: 50% !important;
      }
    }
  }

  @media (max-width: 400px) {
    padding: 0 40px 0 40px !important;
  }

  @media (max-width: 340px) {
    padding: 0 28px 0 28px !important;
  }
`

export const AnswerFQS = styled.div`
  padding: 0px 16px;

  ul,
  ol {
    padding-left: 25px;
  }

  p,
  ul,
  ol {
    font-weight: 600;
    font-size: 18px;
    line-height: 47px;
    color: #757575;

    br {
      display: block;
      content: '';
      margin-top: 0;
    }

    span {
      &.ql-size-small {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 47px;
        color: #757575;
      }

      &.ql-size-large {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 47px;
        color: #757575;
      }

      &.ql-size-huge {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 47px;
        color: #757575;
      }
    }

    strong {
      color: black;
      &.ql-size-small {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 47px;
        color: black;
      }

      &.ql-size-large {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 47px;
        color: black;
      }

      &.ql-size-huge {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 47px;
        color: black;
      }
    }

    img {
      width: 100%;
      max-width: 800px;
    }

    @media (max-width: 600px) {
      font-size: 14px;

      span {
        &.ql-size-small {
          font-size: 10px;
        }

        &.ql-size-large {
          font-size: 18px;
        }

        &.ql-size-huge {
          font-size: 22px;
        }
      }

      strong {
        color: black;
        &.ql-size-small {
          font-size: 10px;
        }

        &.ql-size-large {
          font-size: 18px;
        }

        &.ql-size-huge {
          font-size: 22px;
        }
      }
    }
  }
`
